import React from 'react';
import { Row, Button } from 'antd';
import { BrowserView, MobileView } from 'react-device-detect';

class StudentHeader extends React.Component {
    render() {
        return (
            <div>
                <BrowserView>
                    <div style={{
                        width: '100%', zIndex: 99, top: 0, backgroundColor: '#edededed',
                    }}>
                        {/*    <div style={{width:"100%", zIndex:99, top:0, backgroundColor:"#ff604a"}}> */}
                        <Row>
                            <a href="/">
                                <img src={'/assets/home_graphics/TextAssign.png'} style={{
                                    marginLeft: 15, marginTop: 5, marginBottom: 5, height: 75,
                                }}/>
                            </a>
                            <div style={{
                                position: 'absolute', right: 25, alignItems: 'center', display: 'flex', marginTop: 20,
                            }}>
                                <a href="/"><Button size="large" shape="round" style={{ marginRight: 15, color: '#e03168' }}>About</Button></a>
                                <Button size="large" shape="round" style={{ backgroundColor: '#02ccbf', color: 'white' }}>Teacher Sign Up or Login</Button>
                            </div>
                        </Row>
                    </div>
                </BrowserView>

                <MobileView>
                    <div style={{
                        width: '100%', zIndex: 99, top: 0, backgroundColor: '#edededed',
                    }}>
                        <Row>
                            <a href="/">
                                <img src={'/assets/home_graphics/TextAssign.png'} style={{
                                    marginLeft: 15, marginTop: 5, marginBottom: 5, height: 75,
                                }}/>
                            </a>
                        </Row>
                    </div>
                </MobileView>
            </div>
            // <div style={style}>
            //     <Row style={{}}>
            //         <Col span={17}>
            //             <a href="/"><img style={{width: 250, marginLeft: "1%", marginRight: "120%", marginTop:-22}} src={logo}/></a>
            //         </Col>
            //         <Col span={3}>
            //             <a href="create" className="underline-on-hover"
            //                style={{fontSize: 16, fontWeight: "bold", color: "#000000"}}>
            //                 <p className="vertical-center">
            //                     Create Assignment</p>
            //             </a>
            //         </Col>
            //         <Col span={2}>
            //             <a href="about" style={{fontSize: 16, fontWeight: "bold", color: "#000000"}}>
            //                 <p
            //                     className="vertical-center"
            //                 >About</p>
            //             </a>
            //         </Col>
            //         <Col span={2}>
            //             <div className="vertical-center">
            //                 <Button
            //                     danger
            //                     shape="round"
            //                 >Teacher Sign In</Button>
            //             </div>
            //         </Col>
            //     </Row>
            // </div>
        );
    }
}

export default StudentHeader;

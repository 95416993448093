import React from 'react';
import {
    Button, Row, Modal,
} from 'antd';
import { BrowserView, MobileView } from 'react-device-detect';
import ScrollableAnchor, { configureAnchors } from 'react-scrollable-anchor';
import Footer from '../components /footer';
import StudentHeader from '../components /student_header';
import HelpNotification from '../components /help';

// const layout = {
//     labelCol: { span: 10 },
//     wrapperCol: { span: 4 },
// };
// const tailLayout = {
//     wrapperCol: { offset: 4, span: 16 },
// };

// const menuText = {
//     // top: 10,
//     marginBottom: -10,
//     color: 'black',
//     fontSize: 17,
//     fontWeight: 'semi-bold',
//     display: 'flex',
//     flexDirection: 'row',
//     justifyContent: 'center',
//     alignItems: 'flex-end',
//     // textAlign: "right"
//     // position: "fixed"
// };
// const info = {
//     textAlign: 'left', marginLeft: '2%', marginTop: '2%', color: 'black', fontSize: 16,
// };
configureAnchors({ offset: -84, scrollDuration: 500 });
class Home extends React.Component {
    componentDidMount() {
        document.title = 'TextAssign';
    }

    showModal = () => {
        Modal.info({
            title: 'Not Available on Mobile',
            content: (
                <div>
                    <p>Unfortunately, teachers are not able to sign up or Login on mobile devices at this time. Please visit us from a computer.</p>
                </div>
            ),
            onOk() {},
        });
    }

    hideModel = () => {
        this.setState({
            modalVisible: false,
        });
    }

    render() {
        return (
            <div>
                <HelpNotification/>
                <BrowserView>
                    <div style={{
                        backgroundColor: '#edededed',
                        zIndex: 99,
                        position: 'fixed',
                        top: 0,
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        height: '6em',
                    }}>
                        <div style={{
                            width: '20em',
                            height: '80%',
                            marginLeft: '3em',
                            marginTop: '1em',
                            marginBottom: '1em',
                            backgroundImage: 'url(\'/assets/home_graphics/TextAssign.png\')',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                            backgroundPosition: 'left center',
                            cursor: 'pointer',
                        }} onClick={() => { window.location.href = '/'; }}/>
                        <div style={{ flex: 1 }}> </div>
                        <Button style={{ marginRight: '3em', backgroundColor: '#02ccbf', color: 'white' }}
                            size="large" shape="round" onClick={() => { window.location.href = 'https://dashboard.textassign.org'; }}>Sign Up or Log In</Button>
                    </div>
                    <div style= {{
                        height: '41em', marginTop: '3em', display: 'flex', flexDirection: 'row',
                    }}>
                        <div style={{
                            flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', padding: '3em', marginTop: '2em',
                        }}>
                            <p className="home-big-text">Make Any Article or Reading an Assignment</p>
                            <p className="home-medium-text">With a few clicks, add reading comprehension questions, class discussions, and vocabulary development exercises</p>
                            <div>
                                <Button shape="round" style={{ marginRight: 20, backgroundColor: '#02ccbf', color: 'white' }} onClick={() => { window.location.href = 'https://dashboard.textassign.org'; }} size="large">Sign Up</Button>
                                <a href="#learnmore"><Button shape="round" size="large" style={{ color: '#e03168' }} type="text">Learn More</Button></a>
                            </div>
                        </div>
                        <div style={{
                            flex: 1,
                            height: '550px',
                            backgroundImage: 'url(\'/assets/home_graphics/reading.png\')',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                            backgroundPosition: 'center center',
                            marginTop: '2em',
                        }}/>
                    </div>
                    <ScrollableAnchor id={'learnmore'}>
                        <div style= {{
                            marginTop: '1em', backgroundColor: 'beige', display: 'flex', flexDirection: 'row', padding: '4em',
                        }}>
                            <div style={{
                                flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center',
                            }}>
                                <p style={{ marginBottom: 0 }} className="home-big-text">A Simple Process for Teachers</p>
                                <p style={{ marginBottom: 0 }} className="home-medium-text">
                                    Create an assignment in minutes:</p>
                                <div className="home-medium-text" style={{ textAlign: 'left', maxWidth: '25em' }}>
                                    <p style={{ marginBottom: 0 }}>1. Paste an article link</p>
                                    <p style={{ marginBottom: 0 }}>2. Add questions, discussions, and exercises</p>
                                    <p style={{ marginBottom: 0 }}>3. Share assignment link with students</p>
                                    <p>4. Use RapidScore to grade responses quickly</p>
                                </div>
                                <p className="home-big-text" style={{ marginBottom: 0 }}>Easy Access for Students</p>
                                <ul className="home-medium-text" style={{ textAlign: 'left', maxWidth: '25em' }}>
                                    <li style={{ marginBottom: 0 }}>No student account required</li>
                                    <li style={{ marginBottom: 0 }}>Student site works on mobile and desktop</li>
                                </ul>
                            </div>
                            <div style={{
                                flex: 1,
                                backgroundImage: 'url(\'/assets/home_graphics/home-g1.png\')',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain',
                                backgroundPosition: 'center center',
                            }}/>
                        </div>
                    </ScrollableAnchor>
                    <div style= {{
                        backgroundColor: '#89d6d1', display: 'flex', flexDirection: 'row', padding: '3em',
                    }}>
                        <div style={{ flex: 1, padding: '1em' }}>
                            <p style={{ marginBottom: 0 }} className="home-big-text">A Secure Site</p>
                            <p style={{ marginBottom: 0 }} className="home-medium-text">All assignments are always password protected</p>
                            <div style={{
                                height: '16em',
                                marginTop: '3em',
                                backgroundImage: 'url(\'/assets/home_graphics/lock.png\')',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain',
                                backgroundPosition: 'center center',
                            }}/>
                        </div>
                        <div style={{ flex: 1, padding: '1em' }}>
                            <p className="home-big-text" style={{ marginBottom: 0 }}>A Safe Site</p>
                            <p style={{ marginBottom: 0 }} className="home-medium-text">Students are able to flag inappropriate discussion comments</p>
                            <p style={{ marginBottom: 0 }} className="home-medium-text">Flagged discussion comments are automatically hidden</p>
                            <div style={{
                                height: '14em',
                                marginTop: '3em',
                                backgroundImage: 'url(\'/assets/home_graphics/alert.png\')',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain',
                                backgroundPosition: 'center center',
                            }}/>
                        </div>
                    </div>
                    <div style= {{
                        backgroundColor: 'beige',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: '3em',
                        paddingBottom: '2em',
                    }}>
                        <div style={{
                            flex: 1, display: 'flex', flexDirection: 'row', width: '100%', paddingLeft: '4em', justifyContent: 'flex-start',
                        }}>
                            <div style={{
                                width: '14em',
                                height: '14em',
                                margin: '1em',
                                marginRight: '5em',
                                backgroundImage: 'url(\'/assets/home_graphics/earth.png\')',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain',
                                backgroundPosition: 'center center',
                            }}/>
                            <div style= {{
                                display: 'flex', flexDirection: 'column', alignItems: 'left', textAlign: 'left', justifyContent: 'center',
                            }}>
                                <p style={{ marginBottom: 0 }} className="home-big-text">Built with Foreign Language Teachers in Mind</p>
                                <p style={{ marginBottom: '1em' }} className="home-medium-text">TextAssign finds and provides definition of tricky Spanish words for students.</p>
                            </div>
                        </div>
                        <div style={{ width: '65%', height: '2px', backgroundColor: 'darkgray' }}/>
                        <div style={{
                            flex: 1, display: 'flex', flexDirection: 'row', width: '100%', paddingRight: '4em', justifyContent: 'flex-end',
                        }}>
                            <div style={{
                                display: 'flex', flexDirection: 'column', alignItems: 'right', textAlign: 'right', justifyContent: 'center',
                            }}>
                                <p className="home-big-text" style={{ marginBottom: 0 }}>Have Questions or Ideas?</p>
                                <p style={{ marginBottom: 0 }} className="home-medium-text">Email the TextAssign team at support@textassign.org</p>
                            </div>
                            <div style={{
                                height: '14em',
                                width: '14em',
                                margin: '1em',
                                marginLeft: '5em',
                                marginBottom: 0,
                                backgroundImage: 'url(\'/assets/home_graphics/email.png\')',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'contain',
                                backgroundPosition: 'center center',
                            }}/>
                        </div>
                    </div>
                    <Footer/>
                </BrowserView>
                <MobileView>
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        width: '100%',
                        zIndex: 99,
                        backgroundColor: '#edededed',
                        display: 'flex',
                        flexDirection: 'row',
                        height: '4em',
                        padding: '0.5em',
                        alignItems: 'center',
                    }}>
                        <div style={{
                            flex: 1,
                            height: '100%',
                            backgroundImage: 'url(\'/assets/home_graphics/TextAssign.png\')',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'contain',
                            backgroundPosition: 'left center',
                            cursor: 'pointer',
                        }} onClick={() => { window.location.href = '/'; }}/>
                        <Button shape="round" style={{ backgroundColor: '#02ccbf', color: 'white' }} size="" onClick={this.showModal}>Sign Up or Log In</Button>
                    </div>
                    <div style= {{ marginTop: '1em' }}>
                        <div style={{
                            width: '100%', padding: '2em', marginTop: '4em', paddingBottom: '4em',
                        }}>
                            <p className="home-big-text">
                                    Make Any Article or Reading an Assignment</p>
                            <p className="home-medium-text">With a few clicks, add reading comprehension questions, class discussions, and vocabulary development exercises</p>
                            <Row>
                                <div style={{ width: '100%', alignItems: 'center' }} >
                                    <Button shape="round" style={{ marginRight: 20, backgroundColor: '#02ccbf', color: 'white' }} size="large" onClick={this.showModal}>Sign Up</Button>
                                    <a href="#learnmore"><Button size="large" shape="round" style={{ color: '#e03168' }}>Learn More</Button></a>
                                </div>
                            </Row>
                        </div>
                    </div>
                    <ScrollableAnchor id="learnmore">
                        <div style={{ backgroundColor: 'beige' }}>
                            <div style={{ width: '100%', padding: '2em' }}>
                                <p style={{ marginBottom: 0 }} className="home-big-text">A Simple Process for Teachers</p>
                                <p className="home-medium-text">Create an assignment in minutes:</p>
                                <ol className="home-medium-text" style={{ textAlign: 'left' }}>
                                    <li style={{ marginBottom: 0 }}>Paste an article link</li>
                                    <li style={{ marginBottom: 0 }}>Add questions, discussions, and exercises </li>
                                    <li style={{ marginBottom: 0 }}>Share assignment link with students</li>
                                    <li>Use RapidScore to grade responses quickly</li>
                                </ol>
                                <div style={{
                                    width: '100%', marginTop: '1em', marginBottom: '4em',
                                }}>
                                    <img src={'/assets/home_graphics/home-g1.png'} style={{ width: '100%', height: 'auto' }}/>
                                </div>
                                <div style={{
                                    width: '100%', height: '2px', marginBottom: '2em', backgroundColor: 'darkgray',
                                }}/>
                                <p className="home-big-text" style={{ marginBottom: 0 }}>Easy Access for Students</p>
                                <p style={{ marginBottom: 0 }} className="home-medium-text">No student account required</p>
                                <p style={{ marginBottom: 0 }} className="home-medium-text">Student site works on mobile and desktop </p>

                            </div>
                        </div>
                    </ScrollableAnchor>
                    <div style= {{ backgroundColor: '#89d6d1', padding: '2em' }}>
                        <div style={{ width: '100%' }}>
                            <p style={{ marginBottom: 0 }} className="home-big-text">A Secure Site</p>
                            <p style={{}} className="home-medium-text">All assignments are always password protected</p>
                            <div style={{
                                width: '100%', alignItems: 'center', justifyContent: 'center', marginBottom: '2em',
                            }}>
                                <img src={'/assets/home_graphics/lock.png'} style={{ width: '50%', height: 'auto' }}/>
                            </div>
                        </div>
                        <div style={{
                            width: '100%', height: '2px', marginBottom: '2em', backgroundColor: 'darkgray',
                        }}/>
                        <div style={{ width: '100%' }}>
                            <p className="home-big-text" style={{ marginBottom: 0 }}>A Safe Site</p>
                            <ul style={{ textAlign: 'left' }}>
                                <li style={{ marginBottom: 0 }} className="home-medium-text">Students are able to flag inappropriate discussion comments</li>
                                <li style={{ marginBottom: 0 }} className="home-medium-text">Flagged discussion comments are automatically hidden</li>
                            </ul>
                            <div style={{
                                width: '100%', alignItems: 'center', justifyContent: 'center', marginTop: '2em', marginBottom: '1em',
                            }}>
                                <img src={'/assets/home_graphics/alert.png'} style={{ width: '50%', height: 'auto' }}/>
                            </div>
                        </div>
                    </div>
                    <div style= {{ backgroundColor: 'beige', padding: '1.5em' }}>
                        <div>
                            <div style={{ width: '100%' }}>
                                <p style={{ marginBottom: 0 }} className="home-big-text">Built with Foreign Language Teachers in Mind</p>
                                <p style={{ marginBottom: 0 }} className="home-medium-text">TextAssign finds and provides definition of tricky Spanish words for students.</p>
                                <div style={{
                                    width: '100%', alignItems: 'center', justifyContent: 'center', marginTop: '1em', marginBottom: '3em',
                                }}>
                                    <img src={'/assets/home_graphics/earth.png'} style={{ width: '80%', height: 'auto' }}/>
                                </div>
                            </div>
                            <div style={{
                                width: '100%', height: '2px', marginBottom: '2em', backgroundColor: 'darkgray', marginLeft: '0.5em', marginRight: '0.5em',
                            }}/>
                            <div>
                                <p className="home-big-text" style={{ marginBottom: 0 }}>Have Questions or Ideas?</p>
                                <p style={{}} className="home-medium-text">Email the TextAssign team at support@textassign.org</p>
                                <div style={{
                                    width: '100%', alignItems: 'center', justifyContent: 'center',
                                }}>
                                    <img src={'/assets/home_graphics/email.png'} style={{ width: '50%', height: 'auto' }}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer/>
                </MobileView>
            </div>
        );
    }
}

export default Home;

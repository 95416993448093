import React from 'react';
import {
    Button, Form, Input, Result,
} from 'antd';
import { withRouter } from 'react-router-dom';
import StudentHeader from '../components /student_header';
import Footer from '../components /footer';
import history from '../components /history';
import Constants from '../constants';

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 4 },
};
const tailLayout = {
    wrapperCol: { offset: 4, span: 16 },
};

class StartPage extends React.Component {
    constructor(props) {
        super(props);
        this.onFinish = this.onFinish.bind(this);
        this.state = {
            assignmentID: this.props.match.params.assignmentID,
            password: this.props.match.params.password,
            loaded: false,
            error: false,
            activityName: 'Assignment',
            validateStatus: null,
            help: null,
        };
        this.getData = this.getData.bind(this);
    }

    checkName(firstName, lastName) {
        fetch(`${Constants.SERVER_ENDPOINT}/student/check_name/${firstName}/${lastName}/${this.state.assignmentID}`, {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            },
        })
            .then((response) => {
                if (response.status === 401) {
                    alert('A student with this name has already completed this assignment!');
                    window.location.reload();
                }
                this.props.setName(firstName, lastName);
                history.push('/activity');
            })
            .catch((err) => {
                alert('A student with this name has already completed this assignment!');
                window.location.reload();
            });
    }

    onFinish = (values) => {
        // trim just like strip in python
        const firstName = values['First Name'].trim();
        const lastName = values['Last Name'].trim();
        // check if any non chars
        if (/^[a-zA-Z]+$/.test(firstName) && /^[a-zA-Z]+$/.test(lastName)) {
            this.checkName(firstName, lastName);
        } else {
            this.setState({
                help: 'only letters allowed (no spaces)',
                validateStatus: 'error',
            });
        }
    };

    componentDidMount() {
        this.props.setAssignmentID(this.state.assignmentID);
        this.props.setPassword(this.state.password);
        this.getData();
    }

    getData = async () => {
        fetch(`${Constants.SERVER_ENDPOINT}/assignments/read_with_password/${this.state.assignmentID}/${this.state.password}`)
            .then((response) => response.json())
            .then((response) => {
                this.setState({ loaded: true, error: false, activityName: response.data.title });
            })
            .catch((err) => this.setState({ loaded: true, error: true }));
    }

    render() {
        return (
            <div>
                <div>
                    <div className="background"/>
                    <StudentHeader/>
                    {(this.state.loaded === true && this.state.error === false) && <div style={{ position: 'relative', top: 250 }}>
                        <p style={{ fontWeight: 'bold', color: '#000000', fontSize: 24 }}>{ this.state.activityName }</p>
                        <Form
                            {...layout}
                            hideRequiredMark
                            name="basic"
                            initialValues={{ remember: true }}
                            onFinish={this.onFinish}
                        >
                            <Form.Item
                                validateStatus={this.state.validateStatus}
                                label="First Name"
                                name="First Name"
                                rules={[{ required: true, message: 'Please input your first name' }]}
                                help={this.state.help}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item
                                validateStatus={this.state.validateStatus}
                                label="Last Name"
                                name="Last Name"
                                rules={[{ required: true, message: 'Please input your last name' }]}
                                help={this.state.help}
                            >
                                <Input />
                            </Form.Item>

                            <Form.Item {...tailLayout}>
                                <Button type="primary" danger style={{ color: 'white' }} htmlType="submit">
                                    Start Activity
                                </Button>
                            </Form.Item>
                        </Form>
                    </div>}
                    {this.state.loaded === false && <p>Loading</p>}
                    {this.state.error && <div><br /><br /><Result
                        status="error"
                        title="An Error Occurred"
                        subTitle="Double check your url."
                        extra={[

                        ]}
                    /></div>}
                    <div style={{ position: 'absolute', bottom: 30, width: '100%' }}><Footer/></div></div></div>
        );
    }
}

export default withRouter(StartPage);

import React from 'react';
import {
    Row,
    Form, Input, Button, Radio, Col, Spin, Modal,
} from 'antd';

import { useHistory, withRouter } from 'react-router-dom';
import Footer from '../components /footer';
import history from '../components /history';
import Constants from '../constants';
import RenderDiscussion from '../components /render_discussion';

const { TextArea } = Input;
class Discussion extends React.Component {
    constructor(props) {
        super(props);
        if (this.props.discussions === null) {
            history.push('/home');
        }
        this.discussions = this.props.discussions;
        if (this.props.discussions.length === 0) {
            history.push('/finished');
        }
        this.state = {
            prompt: this.props.discussions[0].prompt,
            discussionID: this.props.discussions[0].id,
            submittedFirstReply: false,
            fullDiscussion: [],
            replyModalVisible: false,
        };
        // this.state = {
        //     prompt: "testing",
        //     discussionID : 1,
        //     submittedFirstReply: false,
        //     fullDiscussion: [],
        //     replyModalVisible: false
        // }
        this.setDiscussion();
        this.renderedDiscussion = (<p>loading...</p>);
    }

    refreshDiscussion = () => {
        this.loadData();
        alert('Discussion Refreshed');
    };

    setDiscussion = () => {
        if (this.discussions.length === 0) {
            history.push('/finished');
        } else {
            this.setState({
                prompt: this.discussions[0].prompt,
                discussionID: this.discussions[0].id,
                submittedFirstReply: false,
                fullDiscussion: [],
                replyModalVisible: false,
            });
            this.forceUpdate();
        }
    }

    handleOk = (e) => {
        this.setState({
            replyModalVisible: false,
        });
    };

    handleCancel = (e) => {
        this.setState({
            replyModalVisible: false,
        });
    };

    loadData = () => {
        fetch(`${Constants.SERVER_ENDPOINT}/assignments/read_discussion_with_password/${this.state.discussionID}/${this.props.password}`, {
            method: 'GET',
            headers: {
            },
        })
            .then((response) => response.json())
            .then((response) => {
                this.setState(
                    {
                        fullDiscussion: response.data,
                    },
                );
                // this.renderDiscussion()
            })
            .catch((err) => {
                console.error(err);
            });
    }

    onFinish = () => {
        this.loadData();
        this.setState(
            { submittedFirstReply: true },
        );
    }

    showReplyModal = () => {
        this.setState({
            replyModalVisible: true,
        });
    }

    hideReplyModal = () => {
        this.setState({
            replyModalVisible: false,
        });
    }

    onReplyClicked = (commentID) => {
        this.commentID = commentID;
        this.showReplyModal();
    }

    submitFirst = (values) => {
        const text = values.text;
        fetch(`${Constants.SERVER_ENDPOINT}/student/submit_discussion`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify({
                first_name: this.props.firstName,
                last_name: this.props.lastName,
                discussion_id: this.state.discussionID,
                password: this.props.password,
                type: 'f',
                reply_text: text,
            }),
        })
            .then((response) => {
                this.loadData();
                this.setState(
                    { submittedFirstReply: true },
                );
            })
            .catch((err) => {
                console.error(err);
            });
    }

    submitReply = (values) => {
        const text = values.text;
        fetch(`${Constants.SERVER_ENDPOINT}/student/submit_discussion`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify({
                first_name: this.props.firstName,
                last_name: this.props.lastName,
                discussion_id: this.state.discussionID,
                password: this.props.password,
                type: 'r',
                reply_text: text,
                reply_to_id: this.commentID,
            }),
        })
            .then((response) => {
                this.hideReplyModal();
                this.loadData();
            })
            .catch((err) => {
                console.error(err);
            });
    }

    onFinish = () => {
        this.discussions.shift(); // removes first element
        this.setDiscussion();
        history.push('/finished');
    }

    render() {
        return (
            <div>
                <div className="background"/>
                <div><Row span={12} style={{ backgroundColor: '#edededed' }}>
                    <Col span={22}><h2 style={{
                        textAlign: 'center', fontWeight: 'bold', marginTop: 10, marginLeft: '10%',
                    }}>{this.props.title}</h2></Col>
                    <Col span={2}><h3 style={{ textAlign: 'center', marginTop: 10 }}>{this.props.name}</h3></Col>
                </Row>
                <div style={{
                    textAlign: 'center', color: 'black', marginLeft: '0%', marginTop: '1%',
                }}>
                    <h2>Discussion</h2>
                    {!this.state.submittedFirstReply
                            && <div>
                                <Form
                                    hideRequiredMark
                                    onFinish={this.submitFirst}
                                    name="basic"
                                    initialValues={{
                                        remember: true,
                                    }}
                                >
                                    <p style={{ fontSize: 17 }}>{this.state.prompt}</p>
                                    <Form.Item
                                        name="text"
                                        rules={[{ required: true, message: 'Please answer this question' }]}
                                    >
                                        <TextArea rows={5} style={{ width: 400 }}/>
                                    </Form.Item>
                                    <Form.Item>
                                        <Button type="primary" htmlType="submit">Submit Response</Button>
                                    </Form.Item>

                                </Form>
                            </div>
                    }
                    {this.state.submittedFirstReply
                            && <div>
                                <p>{this.state.prompt}</p>
                                <RenderDiscussion fullDiscussion={this.state.fullDiscussion} onReply={this.onReplyClicked} hideFlagged={true} password={this.props.password} refreshDiscussion={this.refreshDiscussion} onFinish={this.onFinish}/>
                            </div>
                    }
                    {/* <p>{this.props.discussions}</p> */}
                    <Modal
                        title="Reply"
                        visible={this.state.replyModalVisible}
                        onOk={this.handleOk}
                        onCancel={this.handleCancel}
                        footer={[]}
                    >
                        <Form
                            onFinish={this.submitReply}
                        >
                            <Form.Item
                                name="text"
                                rules={[{ required: true, message: 'Please answer this question' }]}
                            >
                                <TextArea rows={5} style={{ width: 400 }}/>
                            </Form.Item>
                            <Row>
                                <Button key="back" onClick={this.handleCancel}>
                                Cancel
                                </Button>
                                <Form.Item>
                                    <Button style={{ marginLeft: 10 }} type="primary" htmlType="submit">
                                Submit
                                    </Button>
                                </Form.Item>
                            </Row>
                        </Form>
                    </Modal>
                </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Discussion);

import React from 'react';

class Credits extends React.Component {
    render() {
        return (
            <div>
                <h2>Credits</h2>
                <a href="https://www.freepik.com/vectors/school">School vector created by pch.vector - www.freepik.com</a>
            </div>
        )
    }
}

export default Credits;

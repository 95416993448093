import React from 'react';
import { Row } from 'antd';
import StudentHeader from '../components /student_header';
import Footer from '../components /footer';
import confetti from '../confetti.mp4';

class Finished extends React.Component {
    constructor(props) {
        super(props);
        this.timestamp = `${new Date().getMonth()}/${new Date().getDate()} @${new Date().getHours()}:${new Date().getMinutes()}`;
    }

    state = {
        firstName: 'Joe',
        lastName: 'Blow',
    }

    render() {
        return (
            <div>
                <StudentHeader/>
                <div className="background"/>
                <div style={{
                    marginTop: '10%', position: 'absolute', left: 0, right: 0,
                }}>
                    <video id="background-video" style={{ width: 350 }} className='videoTag' autoPlay loop muted>
                        <source src={confetti} type='video/mp4' />
                    </video>
                    <h1>Assignment Submitted!</h1>
                    <p style={{ fontSize: 20 }}>
                        {this.props.firstName} {this.props.lastName}
                    </p>
                    <p style={{ fontSize: 20 }}>
                        {this.timestamp}
                    </p>
                </div>
                <div style={{ position: 'absolute', bottom: 30, width: '100%' }}><Footer/></div>
            </div>
        );
    }
}

export default Finished;

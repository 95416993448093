import React from 'react';
import './App.css';
import 'antd/dist/antd.css';
import ReactGA from 'react-ga';
import {
    Router,
    Switch,
    Route,
} from 'react-router-dom';
import StartPage from './pages/start_page';
import Home from './pages/home';
import Activity from './pages/activity';
import Finished from './pages/finished';
import history from './components /history';
import Discussion from './pages/discussion';
import PrecreatedAssignments from './pages/precreated_assignments';
import Credits from './pages/credits';

ReactGA.initialize('UA-179240119-1');
ReactGA.pageview(`main://${window.location.pathname}${window.location.search}`);

class App extends React.Component {
    state = {
        firstName: null,
        lastName: null,
        discussions: null,
        title: null,
        assignmentID: null,
        password: null,
    };

    setName = (newFirstName, newLastName) => {
        this.setState({
            firstName: newFirstName,
            lastName: newLastName,
        });
    }

    setDiscussions = (discussions) => {
        this.setState({
            discussions,
        });
    };

    setTitle = (title) => {
        this.setState({
            title,
        });
    };

    setAssignmentID = (id) => {
        this.setState({
            assignmentID: id,
        });
    };

    setPassword = (password) => {
        this.setState({
            password,
        });
    };

    render() {
        const name = `${this.state.lastName}, ${this.state.firstName}`;
        return (
            <div className="App">
                <Router history={history}>
                    <Switch>
                        <Route path="/start/:assignmentID/:password">
                            <StartPage setName={this.setName} setAssignmentID={this.setAssignmentID} setPassword={this.setPassword}/>
                        </Route>
                        <Route path="/activity">
                            <Activity name={name} firstName={this.state.firstName} lastName={this.state.lastName} assignmentID={this.state.assignmentID} password={this.state.password} setTitle={this.setTitle} setDiscussions={this.setDiscussions}/>
                        </Route>
                        <Route path="/finished">
                            <Finished firstName={this.state.firstName} lastName={this.state.lastName}/>
                        </Route>
                        <Route path="/discussion">
                            <Discussion name={name} firstName={this.state.firstName} lastName={this.state.lastName} discussions={this.state.discussions} password={this.state.password} title={this.state.title}/>
                        </Route>
                        <Route path="/precreated">
                            <PrecreatedAssignments/>
                        </Route>
                        <Route path="/credits">
                            <Credits/>
                        </Route>
                        <Route path="/">
                            <Home/>
                        </Route>
                    </Switch>
                </Router>
            </div>
        );
    }
}

export default App;

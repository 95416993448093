import React from 'react';
import {
    Row,
    Form, Input, Button, Radio, Col, Spin,
} from 'antd';

import { useHistory, withRouter } from 'react-router-dom';
import Footer from '../components /footer';
import history from '../components /history';
import Constants from '../constants';

const { TextArea } = Input;
const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
class Activity extends React.Component {
    constructor(props) {
        super(props);
        this.renderWordsToKnow = this.renderWordsToKnow.bind(this);
        this.renderQuestions = this.renderQuestions.bind(this);
        this.getData = this.getData.bind(this);
        // this.state = {}
    }

    state = {
        assignmentID: this.props.assignmentID,
        password: this.props.password,
        loaded: false,
        error: false,
        invalid_url: false,
    }

    componentDidMount() {
        if (this.props.firstName === null) {
            const startLink = /start/ + window.location.href.split('/')[window.location.href.split('/').length - 1];
            history.push(startLink);
        }
        this.getData();
    }

    getData = () => {
        fetch(`${Constants.SERVER_ENDPOINT}/assignments/read_with_password/${this.state.assignmentID}/${this.state.password}`, {
            method: 'GET',
            headers: {
                'content-type': 'application/json',
            },
        })
            .then((response) => response.json())
            .then((data) => {
                this.props.setDiscussions(data.data.discussions);
                this.props.setTitle(data.data.title);
                this.setState(
                    {
                        assignmentData: data,
                        title: data.data.title,
                    },
                );
            });
    };

    renderWordsToKnow() {
        const words = this.state.assignmentData.data.new_words;
        const splitWords = words.split(',');
        if (words === '') {
            return <p>No words provided</p>;
        }
        const renderedWords = (
            <Row>
                {Object.entries(splitWords).map(([key, index]) => (
                    <p style={{ color: 'black', marginRight: 20 }}>{index};</p>
                ))}
            </Row>);
        return renderedWords;
    }

    renderVocabularyBuilder() {
        const numVocabBuilder = this.state.assignmentData.data.num_vocab_builder;
        const builder = [...Array(numVocabBuilder)].map((e, i) => (
            <div>
                <Row>
                    <Form.Item
                        label={`New Word ${i + 1}`}
                        name={`nw ${i}`}
                        rules={[{ required: true, message: 'Please answer this question' }]}
                    >
                        <Input style={{ width: 100, marginRight: 20 }}/>
                    </Form.Item>
                    <Form.Item
                        label="Sentence with new word"
                        name={`sentence ${i}`}
                        rules={[{ required: true, message: 'Please answer this question' }]}
                    >
                        <Input style={{ width: 400 }}/>
                    </Form.Item>
                </Row>
            </div>
        ));
        return builder;
    }

    renderQuestions() {
        const questions = this.state.assignmentData.data.questions;
        const renderedQuestions = (
            <div style={{ textAlign: 'left', color: 'black' }}>
                {
                    questions.map((item) => {
                        const id = item.id;

                        // mc is multiple choice question
                        if (item.type === 'mc') {
                            return (
                                <div>
                                    <p>{item.question_text}</p>
                                    <Form.Item
                                        label=""
                                        name={id}
                                        rules={[{ required: true, message: 'Please answer this question' }]}
                                    >
                                        <Radio.Group>
                                            {item.ans_options.map((ans, index) => (
                                                <Radio style={{ color: 'black' }} value={index}> {alphabet[index]}. {ans}</Radio>
                                            ))}
                                        </Radio.Group>
                                    </Form.Item>
                                </div>
                            );
                        }

                        // frq is free response question (longer)
                        if (item.type === 'frq') {
                            return (
                                <div>
                                    <p>{item.question_text}</p>
                                    <Form.Item
                                        label="Longer Response"
                                        name={id}
                                        rules={[{ required: true, message: 'Please answer this question' }]}
                                    >
                                        <TextArea rows={5} style={{ width: 300 }}/>
                                    </Form.Item>
                                </div>
                            );
                        }

                        // saq is short response
                        if (item.type === 'saq') {
                            return (
                                <div>
                                    <p>{item.question_text}</p>
                                    <Form.Item
                                        label="Short Response"
                                        name={id}
                                        rules={[{ required: true, message: 'Please answer this question' }]}
                                    >
                                        <Input style={{ width: 300 }}/>
                                    </Form.Item>
                                </div>
                            );
                        }

                        return null;
                    })
                }
            </div>);
        return renderedQuestions;
    }

    onFinish = (values) => {
        const responsesList = [];
        for (let key in values) {
            let value = values[key];
            if (key.split(' ')[0] === 'nw') {
                const index = key.split(' ')[1];
                value = `${values[key]}; ${values[`sentence ${index}`]}`;
                key = 0;
            } else if (key.split(' ')[0] === 'sentence') {
                // eslint-disable-next-line no-continue
                continue;
            }

            responsesList.push({
                // eslint-disable-next-line radix
                question_id: parseInt(key),
                given_response: value,
            });
            // Do stuff. ex: console.log(dict[key])
        }

        fetch(`${Constants.SERVER_ENDPOINT}/student/submit_assignment`, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                first_name: this.props.firstName,
                last_name: this.props.lastName,
                assignment_id: this.state.assignmentID,
                password: this.state.password,
                question_responses: responsesList,
            }),
        })
            .then((response) => {
            })
            .catch((err) => {
                console.error(err);
            });
        if (this.hasDiscussion) {
            history.push('/discussion');
        } else {
            history.push('/finished');
        }
    };

    render() {
        // let form = this.getForm()
        const assignmentData = this.state.assignmentData;
        let loading = false;
        let invalidLink = false;
        let wordsToKnow = <p>loading...</p>;
        let vocabBuilder = <p>loading...</p>;
        let questions = <p>loading...</p>;
        let link = <p>loading...</p>;
        this.hasDiscussion = false;
        if (assignmentData === undefined) {
            loading = true;
        } else if (assignmentData.status !== 'success') {
            invalidLink = true;
        } else {
            wordsToKnow = this.renderWordsToKnow();
            vocabBuilder = this.renderVocabularyBuilder();
            questions = this.renderQuestions();
            link = this.state.assignmentData.data.link;
            if (this.state.assignmentData.data.discussions.length > 0) {
                this.hasDiscussion = true;
            }
        }
        // console.log("render state", this.state["assignmentData"])
        // console.log("loading", loading)
        // console.log("invalidLink", invalidLink)

        return (
            <div>
                <div><Row span={12} style={{ backgroundColor: '#edededed' }}>
                    <Col span={22}><h2 style={{
                        textAlign: 'center', fontWeight: 'bold', marginTop: 10, marginLeft: '10%',
                    }}>{this.state.title}</h2></Col>
                    <Col span={2}><h3 style={{ textAlign: 'center', marginTop: 10 }}>{this.props.name}</h3></Col>
                </Row>
                </div>

                {loading && <Spin style={{ marginTop: 200 }} size="large" />}
                {invalidLink && <h3 style={{ marginTop: 200 }}>Invalid Link</h3>}
                <div style={{
                    marginLeft: '2%', marginTop: '2%', textAlign: 'left', color: 'black',
                }}>

                    <h2>Words to Know</h2>
                    {wordsToKnow}
                    <p>Click the link to read the Article: <a href={link} target="popup">{link}</a></p>

                    <Form
                        hideRequiredMark
                        onFinish={this.onFinish}
                        name="basic"
                        initialValues={{
                            remember: true,
                        }}
                    >
                        {(vocabBuilder.length > 0)
                        && <div>
                            <h2>Vocabulary Builder</h2>
                            <p>Enter new words you found in the article.</p>
                            {vocabBuilder}
                        </div>
                        }

                        <h2>Questions</h2>
                        {questions}

                        <Form.Item>
                            {this.hasDiscussion
                                && <div>
                                    <Button type="primary" htmlType="submit">Continue to Discussion</Button>
                                    <p style={{ fontWeight: 'bold' }}>*You cannot return to this page</p>
                                </div>
                            }
                            {!this.hasDiscussion
                            && <div>
                                <Button type="primary" htmlType="submit">Submit</Button>
                            </div>
                            }
                        </Form.Item>
                    </Form>
                </div>
            </div>
        );
    }
}

export default withRouter(Activity);

import React from 'react';
import { notification } from 'antd';
import {isMobile} from 'react-device-detect';

class HelpNotification extends React.Component {
    constructor(props) {
        super(props);
    }

    componentDidMount() {
        this.notify();
    }

    notify() {
        if (!isMobile) {
            notification.open({
                message: 'Need Help? Have Questions?',
                placement: 'bottomRight',
                description: 'The TextAssign team is here to help. Text us at (650) 701-7340 or email support@textassign.org.',
                duration: 0
            });
        }
    }

    render() {
        return (
            <div>
            </div>
        );
    }
}

export default HelpNotification;

import React from 'react';
import {
    Button, Row, Card, Col, Tooltip, InputNumber,
} from 'antd';
import { ExclamationCircleOutlined, RightCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import Constants from '../constants';
// const fakeDiscussion = [
//     {
//         "discussion_id": 1,
//         "first_name": "Daniel",
//         "flagged": 0,
//         "id": 1,
//         "last_name": "Longo",
//         "reply_text": "a",
//         "reply_to_id": null,
//         "type": "f"
//     },
//     {
//         "discussion_id": 1,
//         "first_name": "Joe",
//         "flagged": 1,
//         "id": 2,
//         "last_name": "Blow",
//         "reply_text": "a",
//         "reply_to_id": 1,
//         "type": "r"
//     },
//     {
//         "discussion_id": 1,
//         "first_name": "Issy",
//         "flagged": 0,
//         "id": 4,
//         "last_name": "c",
//         "reply_text": "I agree",
//         "type": "f"
//     },
//     {
//         "discussion_id": 1,
//         "first_name": "daniel",
//         "flagged": 0,
//         "id": 29,
//         "last_name": "longo",
//         "reply_text": "I agree",
//         "reply_to_id": 1,
//         "type": "r"
//     },
//     {
//         "discussion_id": 1,
//         "first_name": "daniel",
//         "flagged": 0,
//         "id": 31,
//         "last_name": "longo",
//         "reply_text": "a",
//         "reply_to_id": 1,
//         "type": "r"
//     },
//     {
//         "discussion_id": 1,
//         "first_name": "a",
//         "flagged": 0,
//         "id": 41,
//         "last_name": "a",
//         "reply_text": "i agree",
//         "reply_to_id": 1,
//         "type": "r"
//     },
//     {
//         "discussion_id": 1,
//         "first_name": "Daniel",
//         "flagged": 0,
//         "id": 43,
//         "last_name": "Longo",
//         "reply_text": "hi",
//         "reply_to_id": 1,
//         "type": "r"
//     },
//     {
//         "discussion_id": 1,
//         "first_name": "Daniel",
//         "flagged": 2,
//         "id": 3,
//         "last_name": "Longo",
//         "reply_text": "good",
//         "reply_to_id": null,
//         "type": "f"
//     }
//     ]
const max = 5;
const teacherView = false;
class RenderDiscussion extends React.Component {
    constructor(props) {
        super(props);
        this.width = 600;
        if (window.innerWidth < this.width) {
            this.width = window.innerWidth - 100;
        }
    }

    state = {
        curStudent: { name: 'Click a name above' },
        displayAll: true,
    }

    getNames = () => {
        const names = [];
        this.props.fullDiscussion.forEach((item, index) => {
            const curName = `${item.first_name} ${item.last_name}`;
            if (!names.includes(curName)) {
                names.push(curName);
            }
        });
        this.names = names;
    }

    setStudent = (studentName) => {
        const firstName = studentName.split(' ')[0];
        const lastName = studentName.split(' ')[1];
        this.setState({
            curStudent: { name: `${firstName} ${lastName}`, firstName, lastName },
            displayAll: false,
        });
        if (studentName === 'Show All') {
            this.setState({
                displayAll: true,
            });
        }
    };

    renderNames() {
        this.getNames();
        return (
            this.names.map((value, index) => (
                <a onClick={() => this.setStudent((value))}><p style={{ marginLeft: 15, marginTop: 15, lineHeight: 0.7 }}>{value}</p></a>
                // <div onClick={(value) => this.setStudent}><p style={{marginLeft:15, marginTop: 15, lineHeight:.7}}>{value}</p></div>
            ))
        );
    }

    deleteComment(commentID) {
        alert('You deleted a comment');
        fetch(`${Constants.SERVER_ENDPOINT}/assignments/delete_comment/${commentID}`, {
            method: 'DELETE',
            headers: {
                'content-type': 'application/json',
            },
        })
            .then((response) => {
                this.forceUpdate();
            })
            .catch((err) => {
                console.error(err);
            });
    }

    reportComment(commentID) {
        alert('You reported a comment');
        fetch(`${Constants.SERVER_ENDPOINT}/student/report_comment`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json',
            },
            body: JSON.stringify({
                commentID,
                password: this.props.password,
            }),
        })
            .then((response) => {
                this.forceUpdate();
            })
            .catch((err) => {
                console.error(err);
            });
    }

    renderFlagButton(value) {
        if (teacherView) {
            // teachers can delete comments
            return (
                <Tooltip title="Delete Comment">
                    <Button style={{ float: 'right' }} danger type="text" onClick={() => this.deleteComment(value.id)}>
                        <CloseCircleOutlined />
                    </Button>
                </Tooltip>
            );
        }

        if (!teacherView) {
            // students can only report comments
            return (
                <Tooltip title="Report Comment">
                    <Button style={{ float: 'right' }} danger type="text" onClick={() => this.reportComment(value.id)}>
                        <ExclamationCircleOutlined/>
                    </Button>
                </Tooltip>
            );
        }

        return null;
    }

    renderFComment(value, color) {
        return (
            <div style={{ color, marginTop: 10 }}>
                <Card
                    style={{ width: this.width }}
                >
                    <div style={{
                        marginLeft: -15, marginBottom: -15, marginTop: -15, marginRight: -15, textAlign: 'left',
                    }}>
                        <div>
                            <Row>
                                <Col span={22}>
                                    {value.flagged > 1
                                    && <p style={{ color: 'red' }}> This comment was flagged and is hidden from student view</p>
                                    }
                                    <p style={{ fontWeight: 'bold', color }}>{value.first_name}</p>
                                </Col>
                                <Col span={2}>
                                    {this.renderFlagButton(value)}
                                </Col>
                            </Row>
                            <p style={{ color, marginTop: -15, marginBottom: -5 }}>{value.reply_text}</p>
                        </div>
                        <Row>
                            {!teacherView
                                && <Button type="link" style={{ marginLeft: -15 }} onClick={() => this.props.onReply(value.id)}>
                                    <p>Reply <RightCircleOutlined /></p>
                                </Button>
                            }

                        </Row>
                    </div>
                </Card>
            </div>
        );
    }

    renderRComment(value, color) {
        return (
            <div style={{ marginLeft: 35, color, marginTop: 5 }}>
                <Card
                    style={{ width: this.width - 35 }}
                >
                    <div style={{
                        marginLeft: -15, marginBottom: -15, marginTop: -15, marginRight: -15, textAlign: 'left',
                    }}>
                        <Row>
                            <Col span={22}>
                                {value.flagged > 1
                                    && <p style={{ color: 'red' }}> This comment was flagged and is hidden from student view</p>
                                }
                                <p style={{ fontWeight: 'bold', color }}>{value.first_name}</p>
                            </Col>
                            <Col span={2}>
                                {this.renderFlagButton(value)}
                            </Col>
                        </Row>
                        <p style={{ color, marginTop: -15, marginBottom: -5 }}>{value.reply_text}</p>
                    </div>
                </Card>
            </div>
        );
    }

    renderUserComments() {
        let mostRecentF = null;
        return this.props.fullDiscussion.map((value, index) => {
            if (value.type === 'f') {
                mostRecentF = value;
            }
            if ((value.first_name === this.state.curStudent.firstName && value.last_name === this.state.curStudent.lastName) && value.type === 'f') {
                return (
                    <div>
                        {this.renderFComment(value, 'black')}
                    </div>
                );
            }
            if ((value.first_name === this.state.curStudent.firstName && value.last_name === this.state.curStudent.lastName) && value.type === 'r') {
                return (

                    <div>
                        {this.renderFComment(mostRecentF, 'grey')}
                        {this.renderRComment(value, 'black')}
                    </div>
                );
            }

            return null;
        });
    }

    renderDiscussion() {
        let width = 600;
        if (window.innerWidth < width) {
            width = window.innerWidth - 100;
        }
        return (
            this.props.fullDiscussion.map((value, index) => {
            // fakeDiscussion.map((value, index) => {
                if ((value.flagged < 2 && value.flagged !== -1)) {
                    // teachers see all comments
                    return (
                        <div>
                            {(value.type === 'f') && this.renderFComment(value, 'black')
                            }
                            {(value.type === 'r') && this.renderRComment(value, 'black')
                            }
                        </div>
                    );
                }
                if (value.flagged !== -1) {
                    // these comments were reported
                    let marginLeft = 0;
                    if (value.type === 'r') {
                        marginLeft = 35;
                    }
                    return (
                        <div style={{ color: 'black', marginTop: 10, marginLeft }}>
                            <Card
                                style={{ width: (width - marginLeft) }}
                            >
                                <div style={{
                                    marginLeft: -15,
                                    marginBottom: -15,
                                    marginTop: -15,
                                    marginRight: -15,
                                    textAlign: 'left',
                                }}>
                                    <div>
                                        <p style={{ color: 'black' }}>Hidden Comment</p>
                                    </div>
                                </div>
                            </Card>
                        </div>

                    );
                }
                return null;
            })
            // }
        );
    }

    render() {
        const renderedDiscussion = this.renderDiscussion();
        const renderedNames = this.renderNames();
        let width = 650;
        if (window.innerWidth < 600) {
            width = window.innerWidth - 10;
        }
        return (
            <div>
                <div className="background"/>
                <div style={{ justifyContent: 'center', display: 'flex' }}>
                    <div style={{
                        alignItems: 'center', textAlign: 'center', backgroundColor: '#edededed', width, marginBottom: 60,
                    }}>
                        <div style={{
                            marginLeft: 25, marginRight: 25, marginBottom: 25, marginTop: 15,
                        }}>
                            {((this.props.fullDiscussion.length) === 1)
                                && <p>You've made the first comment. Try refreshing the discussion or skip making a reply.</p>
                            }
                            <Button block style={{ marginTop: 15 }} type="primary" onClick={() => this.props.onFinish()}>Next (you can't return to the discussion)</Button>
                            {renderedDiscussion}
                            <Button block style={{ marginTop: 15 }} type="primary" onClick={() => this.props.refreshDiscussion()}>Refresh Discussion</Button>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RenderDiscussion;

import React from 'react';
import {
    Button, Row, List, Avatar, Space,
} from 'antd';

import { MessageOutlined, StarOutlined } from '@ant-design/icons';
import Footer from '../components /footer';

const listData = [];
for (let i = 0; i < 23; i++) {
    listData.push({
        href: 'https://ant.design',
        title: `ant design part ${i}`,
        avatar: 'https://zos.alipayobjects.com/rmsportal/ODTLcjxAfvqbxHnVXCYX.png',
        description:
            'Ant Design, a design language for background applications, is refined by Ant UED Team.',
        content:
            'We supply a series of design principles, practical patterns and high quality design resources (Sketch and Axure), to help people create their product prototypes beautifully and efficiently.',
    });
}
const IconText = ({ icon, text }) => (
    <Space>
        {React.createElement(icon)}
        {text}
    </Space>
);

class PrecreatedAssignments extends React.Component {
    render() {
        return (
            <div>
                <div className="background"/>
                <div style={{
                    backgroundColor: '#edededed', zIndex: 99, top: 0, width: '100%',
                }}>

                    <Row>
                        <a href="/">
                            <img src={'./assets/home_graphics/TextAssign.png'} style={{
                                marginLeft: 15, marginTop: 5, marginBottom: 5, height: 75,
                            }}/>
                        </a>
                        <div style={{
                            position: 'absolute', right: 25, alignItems: 'center', display: 'flex', marginTop: 20,
                        }}>
                            <Button size="large" shape="round" style={{ marginRight: 15, backgroundColor: '#02ccbf', color: 'white' }}>Sign Up</Button>
                            <Button size="large" shape="round" style={{ color: '#e03168' }}>Login</Button>
                        </div>
                    </Row>

                </div>
                <div style={{ marginTop: 30 }}>
                    <h2 style={{ fontWeight: 'bold' }}>Pre-created Assignmnets</h2>
                </div>

                <div
                    style={{
                        width: '90%', marginLeft: '5%', backgroundColor: 'white', textAlign: 'left', color: 'black',
                    }}
                >
                    <List
                        itemLayout="vertical"
                        size="large"
                        pagination={{
                            pageSize: 3,
                        }}
                        dataSource={listData}
                        renderItem={(item) => (
                            <List.Item
                                key={item.title}
                                actions={[
                                    <IconText icon={StarOutlined} text="156" key="list-vertical-star-o" />,
                                    <IconText icon={MessageOutlined} text="2" key="list-vertical-message" />,
                                ]}
                            >
                                <List.Item.Meta
                                    avatar={<Avatar src={item.avatar} />}
                                    title={<a href={item.href}>{item.title}</a>}
                                    description={item.description}
                                />
                                {item.content}
                            </List.Item>
                        )}
                    />
                </div>

                <div style={{ position: 'absolute', width: '100%', bottom: 20 }}>
                    <Footer/>
                </div>
            </div>
        );
    }
}

export default PrecreatedAssignments;

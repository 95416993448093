import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';

const style = {
    backgroundColor: 'white',
    borderTop: '1px solid #E7E7E7',
    textAlign: 'center',
    padding: '20px',
    position: 'relative',
    left: '0',
    bottom: '0',
    height: '90px',
    width: '100%',
};

const phantomDesktop = {
    display: 'block',
    // padding: '20px',
    height: '60px',
    width: '100%',
};

const phantomMobile = {
    display: 'block',
    padding: '4px',
    // height: 420,
    width: '100%',
};

class Footer extends React.Component {
    render() {
        return (
            <div>
                <BrowserView>
                    <div style={phantomDesktop}>
                        <div style={style}>
                            <p style={{ marginTop: '-.5%' }}> Please contact support@textassign.org | <a href={'/privacy'}>Privacy Policy</a> | <a href={'/terms'}> Terms of Service</a> | <a href={'/credits'}>Credits</a></p>
                            <p style={{ marginTop: '-.8%' }}> textassign.org is an Hablame.org Service. | To practice speaking Spanish, visit <a href="https://hablame.org/">hablame.org</a></p>
                            <p style={{ marginTop: '-.8%' }}> © 2020 DDL Ventures, Inc.</p>
                        </div>

                    </div>
                </BrowserView>

                <MobileView>
                    <div style={{
                        height: 70, backgroundColor: 'white', textAlign: 'center', padding: 10, marginBottom: -30,
                    }}>
                        <p style={{ marginBottom: '-1.5%' }}>Please contact support@textassign.org</p>
                        <p style={{ marginBottom: '-1.5%' }}><a href={'/privacy'}>Privacy Policy</a> | <a href={'/terms'}> Terms of Service</a> | <a href={'/credits'}>Credits</a></p>
                        <p style={{ marginBottom: '-1.5%' }}> © 2020 DDL Ventures, Inc.</p>
                    </div>
                </MobileView>
            </div>
        );
    }
}

export default Footer;
